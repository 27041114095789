<template>
	<div class='text-sm leading-6 mb-10'>

		<!-- <h2 class="text-lg font-bold leading-5 text-red-700 mt-4 mb-4">Contract Terms</h2> -->
		
		<h2 class="text-xl font-bold leading-5 text-red-700 mt-4 mb-4">Advertising Agreement & Privacy Policy
            <a class="hover:underline" href="/contract" target="_blank" rel="noopener">
              <svg class="inline-block ml-2 -mt-1" width="18" height="18" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
              </svg>
            </a>
		</h2>
		<h3 class="text-lg font-bold leading-5 text-red-700 mt-4 mb-4">Conditions of Membership Agreement</h3>


		<p class='py-4'>
			<h4 class='text-red-700 leading-5 text-base mb-2'>1. Agreement</h4>

			1.1 By submitting the Membership Application form, the Advertising Party hereby acknowledges and agrees to the conditions of this Membership Agreement (hereinafter referred to as the "Agreement") as set out below.<br/>
			1.2 The Advertising Party shall be entitled solely to those privileges explicitly set forth in this Agreement.<br/>
			1.3 This Agreement is not a transfer or a license of any of The Broker Site's rights.<br/>
		</p>

		<p class='py-4'>
		<h4 class='text-red-700 leading-5 text-base mb-2'>2. Duration</h4>
		2.1 This Agreement is effective upon receipt and acceptance of a Vendor Application Form by The Broker Site.<br/>
		2.2 Either party may terminate this Agreement for any reason, or no reason at all, upon written notice to the other party. The Advertising Party agrees that The Broker Site, in its sole discretion, shall have the right to terminate Member's access to The Broker Site website at any time, for any reason. Termination of the Advertising Party's access to The Broker Site's website shall be deemed sufficient notice of such termination.<br/>
		2.3 Duration of this agreement is indefinite and membership will be billed by The Broker Site on a yearly basis until either party terminates this agreement in writing. Once billed, members cannot cancel their membership.<br/>
		</p>
		<p class='py-4'>
		<h4 class='text-red-700 leading-5 text-base mb-2'>3. Invoices</h4>
		All invoices are due and payable 15 days from the date of invoice.<br/>
		</p>
		<p class='py-4'>
		<h4 class='text-red-700 leading-5 text-base mb-2'>4. Liabilities</h4>
		Parties agree that The Broker Site assumes no liability for losses incurred whether during or resulting from the use or application of the software or the data provided by The Broker Site.<br/>
		Parties agree that The Broker Site shall not be liable for any direct, indirect, special, incidental, or consequential damages, whether based on contract, tort, or any other legal theory, arising out of the sale, service, furnishing performance or use of any information, data or materials sold or provided under this Agreement, including any operational or technical errors.<br/>
		The Advertising Party shall indemnify and hold harmless The Broker Site from any and all such damages and/or related claims. The provisions of this Disclaimer section shall survive the termination of this Agreement.<br/>
		</p>
		<p class='py-4'>
		<h4 class='text-red-700 leading-5 text-base mb-2'>5. Inventory</h4>
		5.1 The advertising Party shall update its inventory at least once every 2 weeks. Failure to comply shall authorize The Broker Site to remove the vendor's inventory at its discretion.<br/>
		5.2 All inventory uploaded by the Advertising Party must be in its stock.<br/>
		5.3 The Advertising Party's username and password are strictly confidential and may not be disclosed to non-members under any circumstance.<br/>
		</p>
		<p class='py-4'>
		<h4 class='text-red-700 leading-5 text-base mb-2'>6. General provisions</h4>
		6.1 The failure of a party to insist upon the performance of any provision of this Agreement or to exercise any right or privilege granted to that party shall not be construed as waiving any such provision and the same shall continue in force.<br/>
		6.2 If any provision of this Agreement is found to be void, the remainder of this Agreement shall survive and remain in full force and shall not thereby be terminated.<br/>
		6.3 This Agreement is entered into at Oosterhout, The Netherlands and shall be governed by laws of the Netherlands.<br/>
		</p>


		<p class='py-4'>
		<h3 class="text-lg font-bold leading-5 text-red-700 mt-4 mb-4">Privacy Policy</h3>

		<h4 class='text-red-700 leading-5 text-base mb-2'>1. Collection and Usage of Information</h4>
		1.1 The Broker Site collects personal information when you apply for a membership account on The Broker Site, and when you access certain sections on The Broker Site.<br/>
		1.2 When you apply for a membership on The Broker Site, we ask for your personal and corporate information.<br/>
		1.3 Once you have submitted your application to The Broker Site, and when you access certain sections on The Broker Site, you are not anonymous to us.<br/>
		1.4 The Broker Site also automatically receives data from your browser, incuding your IP Address, The Broker Site cookies, and the page you requested.<br/>
		1.5 The Broker Site uses this information to:<br/>
		    - Identify you in restricted areas on The Broker Site;<br/>
		    - Log statistics for the usage of The Broker Site;<br/>
		    - Contact you when questions and/or problems occur;<br/>
		    - Inform you of promotions.<br/>
		</p>


		<p class='py-4'>
		<h4 class='text-red-700 leading-5 text-base mb-2'>2. Sharing and Disclosure of Information</h4>
		2.1 The Broker Site will not sell or rent your personal information to anyone.<br/>
		2.2 The Broker Site may send personal information about you to others when:<br/>
		    - We have your consent to do so;<br/>
		    - We need to share your information to provide the product or
		    service you have requested;<br/>
		    - We respond to subpoenas, court orders or legal processes;<br/>
		    - We find that your actions on The Broker Site violate any of our
		    usage guidelines for The Broker Site.<br/>
		</p>
		<p class='py-4'>
		<h4 class='text-red-700 leading-5 text-base mb-2'>3. Cookies</h4>
		3.1 The Broker Site may set and access The Broker Site cookies on your computer.<br/>
		3.2 Advertisers or other companies do not have access to The Broker Site's cookies.<br/>
		3.3 Cookies are used for identification purposes in restricted areas on The Broker Site.<br/>
		</p>
		<p class='py-4'>
		<h4 class='text-red-700 leading-5 text-base mb-2'>4. Accessing and Modifying Personal Information</h4>
		4.1 The Broker Site gives you the ability to edit your TBS Membership Account Information and preferences at any time.<br/>
		</p>
		<p class='py-4'>
		<h4 class='text-red-700 leading-5 text-base mb-2'>5. Security</h4>
		5.1 Your TBS Membership Account Information is password-protected for your privacy and security.<br/>
		</p>
		<p class='py-4'>
		<h4 class='text-red-700 leading-5 text-base mb-2'>6. Changes to this Privacy Policy</h4>
		6.1 The Broker Site may amend this policy from time to time.<br/>
		6.2 If we make any substantial changes in the way we use your personal information we will notify you by posting a prominent announcement on our pages.<br/>
		</p>
	</div>
</template>

<script>

export default {
  name: 'ContractComponent',
}
</script>
